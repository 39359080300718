import {Injectable} from '@angular/core';
import * as Dot from 'dot-object';
import merge from 'deepmerge';
import {AppHttpClient} from '../http/app-http-client.service';
import {BehaviorSubject} from 'rxjs';
import { Labels } from './labels';

@Injectable({
    providedIn: 'root',
})
export class StaticLabels {
    private config$ = new BehaviorSubject<Labels>({});

    public setMultiple(settings: Labels) {
        if (!settings) return;
        const newConfig = {...this.config$.value};
        Object.entries(settings).forEach(([key, value]) => {
            if (value === '0' || value === '1') {
                value = parseInt(value);
            }
            Dot.set(key, value, newConfig);
        });
        this.config$.next(newConfig);
    }

    public merge(config: object) {
        this.config$.next(merge(this.config$.value, config));
    }

    public set(
        name: keyof Labels | any,
        value: Labels[keyof Labels] | any
    ) {
        const newConfig = {...this.config$.value};
        Dot.set(name, value, newConfig);
        this.config$.next(newConfig);
    }

    public replace(config: Labels) {
        this.config$.next(config);
    }

    public get(name: keyof Labels | any, defaultValue: any = null): any {
        const value = Dot.pick(name, this.config$.value);
        if (value == null) {
            return defaultValue;
        } else {
            return value;
        }
    }
    public all$() {
        return this.config$.asObservable();
    }

    public getAll() {
        return this.config$.value;
    }

    public has(name: keyof Labels): boolean {
        return !!Dot.pick(name as string, this.config$.value);
    }
}
